@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700&display=swap');
.stopwatch-root {
  margin: 0;
  padding: 0;
  transition-duration: 0.5s;
  font-family: 'Montserrat', sans-serif;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  margin: 0;
  padding: 0;
  background-color: #E7E7DB;
}
.timer {
  font-family: "Montserrat", "Poppins", "Segoe UI", sans-serif;
  font-size: 3rem;
  font-weight: 600;
  text-align: center;
  margin: 0;
  padding: 0;
}
.stopwatch-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.stopwatch {
  width: 30rem;
  height: 30rem;
  margin: auto;
}
.buttons {
  width: 80%;
  height: 10rem;
  margin-top: 0rem;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.buttons > button {
  width: 10rem;
  height: 5rem;
  border-radius: 5%;
  border: #131f52 0.5rem solid;
  background-color: #E7E7DB;
  font-family: "Montserrat", "Poppins", "Segoe UI", sans-serif;
  font-size: 1.5em;
  font-weight: 600;
  color: #000000;
  cursor: pointer;
  transition-duration: 0.2s;
}
.buttons > button:hover {
  background-color: #131f52;
  color: #E7E7DB;
}
.buttons > button:active {
  background-color: #131f52;
  color: #E7E7DB;
}
.infobox {
  position: absolute;
  top: 4rem;
  right: 1rem;
  width: 20rem;
  height: 10rem;
  margin-top: 0rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.inputs {
  width: 100%;
  height: 10rem;
  margin-top: 0rem;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.info-button {
  position: absolute;
  top: 0rem;
  right: 0rem;
  transition-duration: 0.2s;
}
@media screen and (max-width: 768px) {
  html {
    font-size: 12px;
  }
}
@media screen and (max-width: 576px) {
  html {
    font-size: 8px;
  }
}
