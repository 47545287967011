@charset "utf-8";
@font-face {
	font-family: "ABC Social";
	src: 
		url('../public/fonts/ABCSocialVariableEdu-Regular.woff') format(woff),
		url('../public/fonts/ABCSocialVariableEdu-Regular.woff2') format(woff2);
	font-stretch: normal;
}
@font-face {
	font-family: "ABC Social";
	src: 
		url('../public/fonts/ABCSocialCondensedVariableEdu-Regular.woff') format(woff),
		url('../public/fonts/ABCSocialCondensedVariableEdu-Regular.woff2') format(woff2);
	font-stretch: condensed;
}
@font-face {
	font-family: "ABC Social";
	src: 
		url('../public/fonts/ABCSocialExtendedVariableEdu-Regular.woff') format(woff),
		url('../public/fonts/ABCSocialExtendedVariableEdu-Regular.woff2') format(woff2);
	font-stretch: expanded;
}
html {
	height: 100%;
	position: relative;
	scroll-behavior: smooth;
	overflow-x: hidden;
}
body {
	margin: 0;
	/* background-color: #F2EDE3; */
	background: linear-gradient(to right, rgba(97, 141, 207, 0.2), rgba(68, 200, 207, 0.2));
	font-family: "ABC Social", sans-serif;
	/* color: #041F77; */
}
a {
	text-decoration: none;
}
